import { StatusBar } from 'expo-status-bar';
import React, {useState, useEffect, useRef } from 'react';
import { StyleSheet, Text, View, Image, ImageBackground } from 'react-native';
import { TextInput, TouchableOpacity } from 'react-native-web';
import { Feather } from '@expo/vector-icons'; 
import Toast from 'react-native-toast-message';
import {Auth} from 'aws-amplify';
import {API, graphqlOperation} from 'aws-amplify';
import * as mutations from './src/graphql/mutations';
import * as queries from './src/graphql/queries';

import Amplify from 'aws-amplify'
import config from './src/aws-exports'
Amplify.configure(config)


export default function App() {

  const [opt, setOpt] = useState('whatsapp')
  const [serv, setServ] = useState('free')
  const [countr, setCountr] = useState('SA')
  const [domainn, setDomainn] = useState('No')
  const [wa, setWa] = useState('details')

  const [name, setName] = useState('null')
  const [surname, setSurname] = useState('null')
  const [email, setEmail] = useState('null')
  const [id, setId] = useState('null')
  const [whatsapp, setWhatsapp] = useState('Whatsapp')
  const [phone, setPhone] = useState('0')
  const [spackage, setPackage] = useState('null')
  const [busName, setBusName] = useState('null')
  const [industry, setIndustry] = useState('null')
  const [scountry, setCountry] = useState('null')
  const [domain, setDomain] = useState('null')


  async function putUp(){

    const date = new Date();

    try{
      const userInput = {
              name: name,
              surname: surname,
              createdAt: date.toISOString(),
              updatedAt: date.toISOString(),
              email: email,
              idNumber: id,
              whatsapp: opt,
              country: countr,
              phone: phone,
              package: serv,
              busName: busName,
              industry: industry,
              domain: domainn,
            }

    await API.graphql({ query: mutations.createNewA, variables: {input: userInput}});

    setWa('completed')

    }catch(e){
      console.log(e);
      return; 
    }



    
  }










  const services = ()=>{

    if (serv === 'free'){

    return(
      <View>
        <View style={{marginTop: 20,  alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>

          <View style={{width: 300, height: 100, alignContent: 'center', backgroundColor: '#062a4f', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25}}>
              <TouchableOpacity style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {/* <Text style={{color: 'white'}}>Whatsapp</Text> */}
              <Text style={{fontSize: 20, color: 'white'}}>FREE</Text>
               <Text style={{color: 'white', marginTop: 5}}>1 Page</Text>
            </TouchableOpacity>
           
          </View>

          <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('ecom')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {/* <Text style={{color: 'white'}}>Whatsapp</Text> */}
              <Text style={{fontSize: 20, color: 'grey'}}>FREE</Text>
               <Text style={{color: 'grey', marginTop: 5}}>e-commerce</Text>
            </TouchableOpacity>
           
          </View>

           <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('khula')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {/* <Text style={{color: 'white'}}>Whatsapp</Text> */}
              <Text style={{fontSize: 20, color: 'grey'}}>299pm</Text>
               <Text style={{color: 'grey', marginTop: 5}}>Khula</Text>
            </TouchableOpacity>
           
          </View>

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('15')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{color: 'grey', fontSize: 20, marginTop: 5}}>15pm</Text>
                 <Text style={{ marginTop: 5, color: 'grey'}}>E-Mail Only</Text>
                <Text style={{color: 'grey', marginTop: 5, width: 100, marginTop: 10}}>1 Email 250MB</Text>
              </TouchableOpacity>
          </View> 

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('29')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>29pm</Text>
               <Text style={{ marginTop: 5, color: 'grey'}}>E-Mail Only</Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>3 Emails 250MB</Text>
              </TouchableOpacity>
          </View>

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('79')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>79pm </Text>
                <Text style={{ marginTop: 5,color: 'grey' }}>Gold </Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>6 Emails 500MB SQL, NodeJs</Text>
              </TouchableOpacity>
          </View>

           <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('109')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>109pm </Text>
                <Text style={{ marginTop: 5,color: 'grey' }}>Rose Gold</Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>10 Emails 1GB SQL, Node Js</Text>
              </TouchableOpacity>
          </View>

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('139')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>139pm </Text>
                <Text style={{ marginTop: 5,color: 'grey' }}>Platinum</Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>15 Emails 2GB 2SQL, Node Js</Text>
              </TouchableOpacity>
          </View>

          
        </View>

         

      </View>
       
        )
    }

     if (serv === 'ecom'){

    return(
      <View>
        <View style={{marginTop: 20,  alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>


          <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede'}}>
              <TouchableOpacity onPress={()=> setServ('free')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {/* <Text style={{color: 'white'}}>Whatsapp</Text> */}
              <Text style={{fontSize: 20, color: 'grey'}}>FREE</Text>
               <Text style={{color: 'grey', marginTop: 5}}>1 Page</Text>
            </TouchableOpacity>
           
          </View>

          <View style={{width: 300, height: 100, alignContent: 'center', backgroundColor: '#062a4f', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, marginTop: 10}}>
              <TouchableOpacity style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {/* <Text style={{color: 'white'}}>Whatsapp</Text> */}
              <Text style={{fontSize: 20, color: 'white'}}>FREE</Text>
               <Text style={{color: 'white', marginTop: 5}}>e-commerce</Text>
            </TouchableOpacity>
           
          </View>

          

           <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('khula')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {/* <Text style={{color: 'white'}}>Whatsapp</Text> */}
              <Text style={{fontSize: 20, color: 'grey'}}>299pm</Text>
               <Text style={{color: 'grey', marginTop: 5}}>Khula</Text>
            </TouchableOpacity>
           
          </View>

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('15')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{color: 'grey', fontSize: 20, marginTop: 5}}>15pm</Text>
                 <Text style={{ marginTop: 5, color: 'grey'}}>E-Mail Only</Text>
                <Text style={{color: 'grey', marginTop: 5, width: 100, marginTop: 10}}>1 Email 250MB</Text>
              </TouchableOpacity>
          </View> 

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('29')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>29pm</Text>
               <Text style={{ marginTop: 5, color: 'grey'}}>E-Mail Only</Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>3 Emails 250MB</Text>
              </TouchableOpacity>
          </View>

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('79')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>79pm </Text>
                <Text style={{ marginTop: 5,color: 'grey' }}>Gold </Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>6 Emails 500MB SQL, NodeJs</Text>
              </TouchableOpacity>
          </View>

           <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('109')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>109pm </Text>
                <Text style={{ marginTop: 5,color: 'grey' }}>Rose Gold</Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>10 Emails 1GB SQL, Node Js</Text>
              </TouchableOpacity>
          </View>

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('139')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>139pm </Text>
                <Text style={{ marginTop: 5,color: 'grey' }}>Platinum</Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>15 Emails 2GB 2SQL, Node Js</Text>
              </TouchableOpacity>
          </View>

          
        </View>

         

      </View>
       
        )
    }

    if (serv === 'khula'){

    return(
      <View>
        <View style={{marginTop: 20,  alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>


          <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede'}}>
              <TouchableOpacity onPress={()=> setServ('free')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              <Text style={{fontSize: 20, color: 'grey'}}>FREE</Text>
               <Text style={{color: 'grey', marginTop: 5}}>1 Page</Text>
            </TouchableOpacity>
           
          </View>

          

           <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('ecom')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {/* <Text style={{color: 'white'}}>Whatsapp</Text> */}
                <Text style={{fontSize: 20, color: 'grey'}}>FREE</Text>
               <Text style={{color: 'grey', marginTop: 5}}>e-commerce</Text>
            </TouchableOpacity>
           
          </View>

          <View style={{width: 300, height: 100, alignContent: 'center', backgroundColor: '#062a4f', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, marginTop: 10}}>
              <TouchableOpacity style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              <Text style={{fontSize: 20, color: 'white'}}>299pm</Text>
               <Text style={{color: 'white', marginTop: 5}}>Khula</Text>
            </TouchableOpacity>
           
          </View>

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('15')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{color: 'grey', fontSize: 20, marginTop: 5}}>15pm</Text>
                 <Text style={{ marginTop: 5, color: 'grey'}}>E-Mail Only</Text>
                <Text style={{color: 'grey', marginTop: 5, width: 100, marginTop: 10}}>1 Email 250MB</Text>
              </TouchableOpacity>
          </View> 

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('29')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>29pm</Text>
               <Text style={{ marginTop: 5, color: 'grey'}}>E-Mail Only</Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>3 Emails 250MB</Text>
              </TouchableOpacity>
          </View>

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('79')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>79pm </Text>
                <Text style={{ marginTop: 5,color: 'grey' }}>Gold </Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>6 Emails 500MB SQL, NodeJs</Text>
              </TouchableOpacity>
          </View>

           <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('109')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>109pm </Text>
                <Text style={{ marginTop: 5,color: 'grey' }}>Rose Gold</Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>10 Emails 1GB SQL, Node Js</Text>
              </TouchableOpacity>
          </View>

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('139')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>139pm </Text>
                <Text style={{ marginTop: 5,color: 'grey' }}>Platinum</Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>15 Emails 2GB 2SQL, Node Js</Text>
              </TouchableOpacity>
          </View>

          
        </View>

         

      </View>
       
        )
    }

    if (serv === '15'){

    return(
      <View>
        <View style={{marginTop: 20,  alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>


          <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede'}}>
              <TouchableOpacity onPress={()=> setServ('free')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              <Text style={{fontSize: 20, color: 'grey'}}>FREE</Text>
               <Text style={{color: 'grey', marginTop: 5}}>1 Page</Text>
            </TouchableOpacity>
           
          </View>

          

           <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('ecom')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {/* <Text style={{color: 'white'}}>Whatsapp</Text> */}
                <Text style={{fontSize: 20, color: 'grey'}}>FREE</Text>
               <Text style={{color: 'grey', marginTop: 5}}>e-commerce</Text>
            </TouchableOpacity>
           
          </View>

          <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, marginTop: 10, borderWidth: 0.5, borderColor: '#e0dede'}}>
              <TouchableOpacity onPress={()=> setServ('khula')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              <Text style={{fontSize: 20, color: 'grey'}}>299pm</Text>
               <Text style={{color: 'grey', marginTop: 5}}>Khula</Text>
            </TouchableOpacity>
           
          </View>

          <View style={{width: 300, height: 180, alignContent: 'center',  backgroundColor: 'orange', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('15')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{color: 'white', fontSize: 20, marginTop: 5}}>15pm</Text>
                 <Text style={{ marginTop: 5, color: 'white'}}>E-Mail Only</Text>
                <Text style={{color: 'white', marginTop: 5, width: 100, marginTop: 10}}>1 Email 250MB</Text>
              </TouchableOpacity>
          </View> 

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('29')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>29pm</Text>
               <Text style={{ marginTop: 5, color: 'grey'}}>E-Mail Only</Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>3 Emails 250MB</Text>
              </TouchableOpacity>
          </View>

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('79')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>79pm </Text>
                <Text style={{ marginTop: 5,color: 'grey' }}>Gold </Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>6 Emails 500MB SQL, NodeJs</Text>
              </TouchableOpacity>
          </View>

           <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('109')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>109pm </Text>
                <Text style={{ marginTop: 5,color: 'grey' }}>Rose Gold</Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>10 Emails 1GB SQL, Node Js</Text>
              </TouchableOpacity>
          </View>

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('139')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>139pm </Text>
                <Text style={{ marginTop: 5,color: 'grey' }}>Platinum</Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>15 Emails 2GB 2SQL, Node Js</Text>
              </TouchableOpacity>
          </View>

          
        </View>

         

      </View>
       
        )
    }

    if (serv === '29'){

    return(
      <View>
        <View style={{marginTop: 20,  alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>


          <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede'}}>
              <TouchableOpacity onPress={()=> setServ('free')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              <Text style={{fontSize: 20, color: 'grey'}}>FREE</Text>
               <Text style={{color: 'grey', marginTop: 5}}>1 Page</Text>
            </TouchableOpacity>
           
          </View>

          

           <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('ecom')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {/* <Text style={{color: 'white'}}>Whatsapp</Text> */}
                <Text style={{fontSize: 20, color: 'grey'}}>FREE</Text>
               <Text style={{color: 'grey', marginTop: 5}}>e-commerce</Text>
            </TouchableOpacity>
           
          </View>

          <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, marginTop: 10, borderWidth: 0.5, borderColor: '#e0dede'}}>
              <TouchableOpacity onPress={()=> setServ('khula')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              <Text style={{fontSize: 20, color: 'grey'}}>299pm</Text>
               <Text style={{color: 'grey', marginTop: 5}}>Khula</Text>
            </TouchableOpacity>
           
          </View>

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('15')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{color: 'grey', fontSize: 20, marginTop: 5}}>15pm</Text>
                 <Text style={{ marginTop: 5, color: 'grey'}}>E-Mail Only</Text>
                <Text style={{color: 'grey', marginTop: 5, width: 100, marginTop: 10}}>1 Email 250MB</Text>
              </TouchableOpacity>
          </View> 

          <View style={{width: 300, height: 180, alignContent: 'center',  backgroundColor: 'orange', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('29')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'white', marginTop: 5, fontSize: 20}}>29pm</Text>
               <Text style={{ marginTop: 5, color: 'white'}}>E-Mail Only</Text>
              <Text style={{color: 'white', marginTop: 5, width: 100}}>3 Emails 250MB</Text>
              </TouchableOpacity>
          </View>

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('79')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>79pm </Text>
                <Text style={{ marginTop: 5,color: 'grey' }}>Gold </Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>6 Emails 500MB SQL, NodeJs</Text>
              </TouchableOpacity>
          </View>

           <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('109')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>109pm </Text>
                <Text style={{ marginTop: 5,color: 'grey' }}>Rose Gold</Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>10 Emails 1GB SQL, Node Js</Text>
              </TouchableOpacity>
          </View>

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('139')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>139pm </Text>
                <Text style={{ marginTop: 5,color: 'grey' }}>Platinum</Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>15 Emails 2GB 2SQL, Node Js</Text>
              </TouchableOpacity>
          </View>

          
        </View>

         

      </View>
       
        )
    }

    if (serv === '79'){

    return(
      <View>
        <View style={{marginTop: 20,  alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>


          <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede'}}>
              <TouchableOpacity onPress={()=> setServ('free')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              <Text style={{fontSize: 20, color: 'grey'}}>FREE</Text>
               <Text style={{color: 'grey', marginTop: 5}}>1 Page</Text>
            </TouchableOpacity>
           
          </View>

          

           <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('ecom')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {/* <Text style={{color: 'white'}}>Whatsapp</Text> */}
                <Text style={{fontSize: 20, color: 'grey'}}>FREE</Text>
               <Text style={{color: 'grey', marginTop: 5}}>e-commerce</Text>
            </TouchableOpacity>
           
          </View>

          <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, marginTop: 10, borderWidth: 0.5, borderColor: '#e0dede'}}>
              <TouchableOpacity onPress={()=> setServ('khula')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              <Text style={{fontSize: 20, color: 'grey'}}>299pm</Text>
               <Text style={{color: 'grey', marginTop: 5}}>Khula</Text>
            </TouchableOpacity>
           
          </View>

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('15')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{color: 'grey', fontSize: 20, marginTop: 5}}>15pm</Text>
                 <Text style={{ marginTop: 5, color: 'grey'}}>E-Mail Only</Text>
                <Text style={{color: 'grey', marginTop: 5, width: 100, marginTop: 10}}>1 Email 250MB</Text>
              </TouchableOpacity>
          </View> 

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('29')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>29pm</Text>
               <Text style={{ marginTop: 5, color: 'grey'}}>E-Mail Only</Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>3 Emails 250MB</Text>
              </TouchableOpacity>
          </View>

          <View style={{width: 300, height: 180, alignContent: 'center',  backgroundColor: 'orange', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('79')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'white', marginTop: 5, fontSize: 20}}>79pm </Text>
                <Text style={{ marginTop: 5,color: 'white' }}>Gold </Text>
              <Text style={{color: 'white', marginTop: 5, width: 100}}>6 Emails 500MB SQL, NodeJs</Text>
              </TouchableOpacity>
          </View>

           <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('109')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>109pm </Text>
                <Text style={{ marginTop: 5,color: 'grey' }}>Rose Gold</Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>10 Emails 1GB SQL, Node Js</Text>
              </TouchableOpacity>
          </View>

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('139')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>139pm </Text>
                <Text style={{ marginTop: 5,color: 'grey' }}>Platinum</Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>15 Emails 2GB 2SQL, Node Js</Text>
              </TouchableOpacity>
          </View>

          
        </View>

         

      </View>
       
        )
    }

    if (serv === '109'){

    return(
      <View>
        <View style={{marginTop: 20,  alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>


          <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede'}}>
              <TouchableOpacity onPress={()=> setServ('free')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              <Text style={{fontSize: 20, color: 'grey'}}>FREE</Text>
               <Text style={{color: 'grey', marginTop: 5}}>1 Page</Text>
            </TouchableOpacity>
           
          </View>

          

           <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('ecom')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {/* <Text style={{color: 'white'}}>Whatsapp</Text> */}
                <Text style={{fontSize: 20, color: 'grey'}}>FREE</Text>
               <Text style={{color: 'grey', marginTop: 5}}>e-commerce</Text>
            </TouchableOpacity>
           
          </View>

          <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, marginTop: 10, borderWidth: 0.5, borderColor: '#e0dede'}}>
              <TouchableOpacity onPress={()=> setServ('khula')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              <Text style={{fontSize: 20, color: 'grey'}}>299pm</Text>
               <Text style={{color: 'grey', marginTop: 5}}>Khula</Text>
            </TouchableOpacity>
           
          </View>

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('15')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{color: 'grey', fontSize: 20, marginTop: 5}}>15pm</Text>
                 <Text style={{ marginTop: 5, color: 'grey'}}>E-Mail Only</Text>
                <Text style={{color: 'grey', marginTop: 5, width: 100, marginTop: 10}}>1 Email 250MB</Text>
              </TouchableOpacity>
          </View> 

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('29')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>29pm</Text>
               <Text style={{ marginTop: 5, color: 'grey'}}>E-Mail Only</Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>3 Emails 250MB</Text>
              </TouchableOpacity>
          </View>

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('79')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>79pm </Text>
                <Text style={{ marginTop: 5,color: 'grey' }}>Gold </Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>6 Emails 500MB SQL, NodeJs</Text>
              </TouchableOpacity>
          </View>

           <View style={{width: 300, height: 180, alignContent: 'center',  backgroundColor: 'orange', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('109')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'white', marginTop: 5, fontSize: 20}}>109pm </Text>
                <Text style={{ marginTop: 5,color: 'white' }}>Rose Gold</Text>
              <Text style={{color: 'white', marginTop: 5, width: 100}}>10 Emails 1GB SQL, Node Js</Text>
              </TouchableOpacity>
          </View>

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('139')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>139pm </Text>
                <Text style={{ marginTop: 5,color: 'grey' }}>Platinum</Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>15 Emails 2GB 2SQL, Node Js</Text>
              </TouchableOpacity>
          </View>

          
        </View>

         

      </View>
       
        )
    }

    if (serv === '139'){

    return(
      <View>
        <View style={{marginTop: 20,  alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>


          <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede'}}>
              <TouchableOpacity onPress={()=> setServ('free')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              <Text style={{fontSize: 20, color: 'grey'}}>FREE</Text>
               <Text style={{color: 'grey', marginTop: 5}}>1 Page</Text>
            </TouchableOpacity>
           
          </View>

          

           <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('ecom')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {/* <Text style={{color: 'white'}}>Whatsapp</Text> */}
                <Text style={{fontSize: 20, color: 'grey'}}>FREE</Text>
               <Text style={{color: 'grey', marginTop: 5}}>e-commerce</Text>
            </TouchableOpacity>
           
          </View>

          <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, marginTop: 10, borderWidth: 0.5, borderColor: '#e0dede'}}>
              <TouchableOpacity onPress={()=> setServ('khula')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              <Text style={{fontSize: 20, color: 'grey'}}>299pm</Text>
               <Text style={{color: 'grey', marginTop: 5}}>Khula</Text>
            </TouchableOpacity>
           
          </View>

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('15')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{color: 'grey', fontSize: 20, marginTop: 5}}>15pm</Text>
                 <Text style={{ marginTop: 5, color: 'grey'}}>E-Mail Only</Text>
                <Text style={{color: 'grey', marginTop: 5, width: 100, marginTop: 10}}>1 Email 250MB</Text>
              </TouchableOpacity>
          </View> 

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('29')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>29pm</Text>
               <Text style={{ marginTop: 5, color: 'grey'}}>E-Mail Only</Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>3 Emails 250MB</Text>
              </TouchableOpacity>
          </View>

          <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('79')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>79pm </Text>
                <Text style={{ marginTop: 5,color: 'grey' }}>Gold </Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>6 Emails 500MB SQL, NodeJs</Text>
              </TouchableOpacity>
          </View>

           <View style={{width: 300, height: 180, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('109')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'grey', marginTop: 5, fontSize: 20}}>109pm </Text>
                <Text style={{ marginTop: 5,color: 'grey' }}>Rose Gold</Text>
              <Text style={{color: 'grey', marginTop: 5, width: 100}}>10 Emails 1GB SQL, Node Js</Text>
              </TouchableOpacity>
          </View>

          <View style={{width: 300, height: 180, alignContent: 'center',  backgroundColor: 'orange', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setServ('139')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
               <Text style={{color: 'white', marginTop: 5, fontSize: 20}}>139pm </Text>
                <Text style={{ marginTop: 5,color: 'white' }}>Platinum</Text>
              <Text style={{color: 'white', marginTop: 5, width: 100}}>15 Emails 2GB 2SQL, Node Js</Text>
              </TouchableOpacity>
          </View>

          
        </View>

         

      </View>
       
        )
    }



    
   
  }

   const country = ()=>{

    if (countr === 'SA'){

    return(
      <View>
        <View style={{marginTop: 20,  alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>

          <View style={{width: 300, height: 100, alignContent: 'center', backgroundColor: '#062a4f', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25}}>
              <TouchableOpacity style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {/* <Text style={{color: 'white'}}>Whatsapp</Text> */}
              <Text style={{fontSize: 20, color: 'white'}}>South Africa</Text>
               <Text style={{color: 'white', marginTop: 5}}>Rand</Text>
            </TouchableOpacity>
           
          </View>

          <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setCountr('SZ')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {/* <Text style={{color: 'white'}}>Whatsapp</Text> */}
              <Text style={{fontSize: 20, color: 'grey'}}>Eswatini</Text>
               <Text style={{color: 'grey', marginTop: 5}}>Lilangeni</Text>
            </TouchableOpacity>
           
          </View>

    
        </View>

      </View>
       
        )
    }

     if (countr === 'SZ'){

    return(
      <View>
        <View style={{marginTop: 20,  alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>


          <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede'}}>
              <TouchableOpacity onPress={()=> setCountr('SA')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {/* <Text style={{color: 'white'}}>Whatsapp</Text> */}
              <Text style={{fontSize: 20, color: 'grey'}}>South Africa</Text>
               <Text style={{color: 'grey', marginTop: 5}}>Rand</Text>
            </TouchableOpacity>
           
          </View>

          <View style={{width: 300, height: 100, alignContent: 'center', backgroundColor: '#062a4f', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, marginTop: 10}}>
              <TouchableOpacity style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
             <Text style={{fontSize: 20, color: 'white'}}>Eswatini</Text>
               <Text style={{color: 'white', marginTop: 5}}>Lilangeni</Text>
            </TouchableOpacity>
           
          </View>

                   
        </View>

         

      </View>
       
        )
    }

  }

   const domaina = ()=>{

    if (domainn === 'No'){

    return(
      <View>
        <View style={{marginTop: 20,  alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>

          <View style={{width: 300, height: 100, alignContent: 'center', backgroundColor: '#062a4f', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25}}>
              <TouchableOpacity style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {/* <Text style={{color: 'white'}}>Whatsapp</Text> */}
              <Text style={{fontSize: 20, color: 'white'}}>No</Text>
               <Text style={{color: 'white', marginTop: 5}}>I don't have a domain</Text>
            </TouchableOpacity>
           
          </View>

          <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setDomainn('Yes')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {/* <Text style={{color: 'white'}}>Whatsapp</Text> */}
              <Text style={{fontSize: 20, color: 'grey'}}>Yes</Text>
               <Text style={{color: 'grey', marginTop: 5}}>Yes I do, I will transfer</Text>
            </TouchableOpacity>
           
          </View>

    
        </View>

      </View>
       
        )
    }

     if (domainn === 'Yes'){

    return(
      <View>
        <View style={{marginTop: 20,  alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>


          <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede'}}>
              <TouchableOpacity onPress={()=> setDomainn('No')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {/* <Text style={{color: 'white'}}>Whatsapp</Text> */}
              <Text style={{fontSize: 20, color: 'grey'}}>No</Text>
               <Text style={{color: 'grey', marginTop: 5}}>I don't have a domain</Text>
            </TouchableOpacity>
           
          </View>

          <View style={{width: 300, height: 100, alignContent: 'center', backgroundColor: '#062a4f', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, marginTop: 10}}>
              <TouchableOpacity style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
             <Text style={{fontSize: 20, color: 'white'}}>Yes</Text>
               <Text style={{color: 'white', marginTop: 5}}>Yes I do, I will transfer</Text>
            </TouchableOpacity>
           
          </View>

                   
        </View>

         

      </View>
       
        )
    }

  }

  const options = ()=>{

    if (opt === 'whatsapp'){

    return(
      <View>
        <View style={{marginTop: 20,  alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>

          <View style={{width: 300, height: 100, alignContent: 'center', backgroundColor: '#062a4f', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25}}>
              <TouchableOpacity style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {/* <Text style={{color: 'white'}}>Whatsapp</Text> */}
              <Text style={{fontSize: 20, color: 'white'}}>WhatsApp</Text>
               {/* <Text style={{color: 'white', marginTop: 5}}>I don't have a domain</Text> */}
            </TouchableOpacity>
           
          </View>

          <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede', marginTop: 10}}>
              <TouchableOpacity onPress={()=> setOpt('call')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {/* <Text style={{color: 'white'}}>Whatsapp</Text> */}
              <Text style={{fontSize: 20, color: 'grey'}}>Call</Text>
               {/* <Text style={{color: 'grey', marginTop: 5}}>Yes I do, I will transfer</Text> */}
            </TouchableOpacity>
           
          </View>

    
        </View>

      </View>
       
        )
    }

     if (opt === 'call'){

    return(
      <View>
        <View style={{marginTop: 20,  alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>


          <View style={{width: 300, height: 100, alignContent: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, borderWidth: 0.5, borderColor: '#e0dede'}}>
              <TouchableOpacity onPress={()=> setOpt('whatsapp')} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
              {/* <Text style={{color: 'white'}}>Whatsapp</Text> */}
              <Text style={{fontSize: 20, color: 'grey'}}>WhatsApp</Text>
            </TouchableOpacity>
           
          </View>

          <View style={{width: 300, height: 100, alignContent: 'center', backgroundColor: '#062a4f', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 25, marginTop: 10}}>
              <TouchableOpacity style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
             <Text style={{fontSize: 20, color: 'white'}}>Call</Text>
            </TouchableOpacity>
           
          </View>

                   
        </View>

         

      </View>
       
        )
    }

  }


 

  const myDetaill = ()=>{

    return(
   <View style={styles.container}>

              <View style={{width: 400, borderRadius: 5, alignContent: 'center', alignContent: 'center', justifyContent: 'center'}}>

                <View style={{width: 400, height: 150, justifyContent: 'center', alignItems: 'center', alignContent: 'center'}}> 
                  <Image
                        style={{flex: 1,
                  width: 200,
                  height: null,
                  resizeMode: 'contain'}}
                        source={require('./assets/real.png')}
                      />

                </View>

                <View style={{width: 400, borderRadius: 5, alignContent: 'center', alignContent: 'center', justifyContent: 'center'}}>
                  <Text>Center</Text>
                </View>

                <View style={{backgroundColor:'#ebebeb', marginLeft: 5, height: 45, width: 300, borderRadius: 5}}>
                    <TextInput style={{color:'black', paddingLeft: 10, height: 40}}
                    placeholder="First Name"
                    // onChangeText={val => setQty(val)}
                    // value={qty}
                    placeholderTextColor="#7d7d7d"
                    />
               
                </View>

                 <View style={{backgroundColor:'#ebebeb', marginLeft: 5, height: 45, width: 300, borderRadius: 5, marginTop: 15}}>
                    <TextInput style={{color:'black', paddingLeft: 10, height: 40}}
                    placeholder="Surname"
                    // onChangeText={val => setQty(val)}
                    // value={qty}
                    placeholderTextColor="#7d7d7d"
                    />
               
                </View>

                <View style={{backgroundColor:'#ebebeb', marginLeft: 5, height: 45, width: 300, borderRadius: 5, marginTop: 15}}>
                    <TextInput style={{color:'black', paddingLeft: 10, height: 40}}
                    placeholder="E- Mail Address"
                    keyboardType='numeric'
                    // onChangeText={val => setQty(val)}
                    // value={qty}
                    placeholderTextColor="#7d7d7d"
                    />
               
                </View>
                

                

                  

              </View>

              <TouchableOpacity style={{alignContent: 'center', alignItems: 'center'}} onPress={()=> setWa('contact')} style={{width: 50, height: 50, borderRadius: 50, backgroundColor: 'orange', marginTop: 40, marginLeft: 20}}>
                    <View style={{alignContent: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 11}}>
                        {/* <Text style={{color: 'white'}}>Next</Text>  */}
                        <Feather name="arrow-right" size={24} color="white" />      
                    </View>
              </TouchableOpacity>

  
      </View>
    )

  }



  const myDeta = ()=>{

    return(
   <View style={styles.container}>

                

                  <View style={{width: 400, height: 150, justifyContent: 'center', alignItems: 'center', alignContent: 'center'}}> 
                  <Image
                        style={{flex: 1,
                  width: 200,
                  height: null,
                  resizeMode: 'contain'}}
                        source={require('./assets/real.png')}
                      />

                </View>

                <View style={{backgroundColor:'#ebebeb', marginLeft: 5, height: 45, width: 300, borderRadius: 5}}>
                    <TextInput style={{color:'black', paddingLeft: 10, height: 40}}
                    placeholder="First Name"
                    onChangeText={val => setName(val)}
                    // value={qty}
                    placeholderTextColor="#7d7d7d"
                    />
               
                </View>

                 <View style={{backgroundColor:'#ebebeb', marginLeft: 5, height: 45, width: 300, borderRadius: 5, marginTop: 15}}>
                    <TextInput style={{color:'black', paddingLeft: 10, height: 40}}
                    placeholder="Surname"
                    onChangeText={val => setSurname(val)}
                    // value={qty}
                    placeholderTextColor="#7d7d7d"
                    />
               
                </View>

                 <View style={{backgroundColor:'#ebebeb', marginLeft: 5, height: 45, width: 300, borderRadius: 5, marginTop: 15}}>
                    <TextInput style={{color:'black', paddingLeft: 10, height: 40}}
                    placeholder="E- Mail Address"
                    onChangeText={val => setEmail(val)}
                    // value={qty}
                    placeholderTextColor="#7d7d7d"
                    />
               
                </View>
                <View style={{backgroundColor:'#ebebeb', marginLeft: 5, height: 45, width: 300, borderRadius: 5, marginTop: 15}}>
                    <TextInput style={{color:'black', paddingLeft: 10, height: 40}}
                    placeholder="ID Number / Passport"
                    keyboardType='numeric'
                    onChangeText={val => setId(val)}
                    // value={qty}
                    placeholderTextColor="#7d7d7d"
                    />
               
                </View>

                
                  <View style={{backgroundColor:'#ebebeb', marginLeft: 5, height: 45, width: 300, borderRadius: 5, marginTop: 15}}>
                    <TextInput style={{color:'black', paddingLeft: 10, height: 40}}
                    placeholder="Number | +27676837717"
                    onChangeText={val => setPhone(val)}
                    keyboardType='numeric'
                    // value={phone}
                    placeholderTextColor="#7d7d7d"
                    />
                  </View>

              

                <TouchableOpacity style={{alignContent: 'center', alignItems: 'center'}} onPress={()=> setWa('contact')} style={{width: 50, height: 50, borderRadius: 50, backgroundColor: 'orange', marginTop: 40, marginLeft: 20}}>
                    <View style={{alignContent: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 11}}>
                        {/* <Text style={{color: 'white'}}>Next</Text>  */}
                        <Feather name="arrow-right" size={24} color="white" />      
                    </View>
              </TouchableOpacity>

      </View>
    )

  }

  const myContact = ()=>{

    return(
   <View style={styles.container}>

                <View style={{color:'black', paddingLeft: 10, marginTop: 20}}>
                    <Text>How would you like to be contacted ?</Text>  
                  </View>

                  <View style={{width: '100%', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
                      {options()}

                  </View>

              



            

             <View style={{flexDirection: 'row'}}>
                <TouchableOpacity style={{alignContent: 'center', alignItems: 'center'}} onPress={()=> setWa('details')} style={{width: 50, height: 50, borderRadius: 50, backgroundColor: 'orange', marginTop: 40, marginLeft: 20}}>
                    <View style={{alignContent: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 11}}>
                        {/* <Text style={{color: 'white'}}>Next</Text>  */}
                        <Feather name="arrow-left" size={24} color="white" />      
                    </View>
              </TouchableOpacity>


                <TouchableOpacity style={{alignContent: 'center', alignItems: 'center', marginLeft: 20}} onPress={()=> setWa('services')} style={{width: 50, height: 50, borderRadius: 50, backgroundColor: 'orange', marginTop: 40, marginLeft: 20}}>
                    <View style={{alignContent: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 11}}>
                        {/* <Text style={{color: 'white'}}>Next</Text>  */}
                        <Feather name="arrow-right" size={24} color="white" />      
                    </View>
              </TouchableOpacity>

             </View>


              


      </View>
    )

  }
 
  const myServices = ()=>{

    return(
   <View style={styles.container}>

                <View style={{color:'black', paddingLeft: 10, marginTop: 20}}>
                    <Text>Which package would you like to sign up to ?</Text>  
                  </View>

                  <View style={{width: '100%', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
                      {services()}

                  </View>

            

             <View style={{flexDirection: 'row'}}>
                <TouchableOpacity style={{alignContent: 'center', alignItems: 'center'}} onPress={()=> setWa('contact')} style={{width: 50, height: 50, borderRadius: 50, backgroundColor: 'orange', marginTop: 40, marginLeft: 20}}>
                    <View style={{alignContent: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 11}}>
                        {/* <Text style={{color: 'white'}}>Next</Text>  */}
                        <Feather name="arrow-left" size={24} color="white" />      
                    </View>
              </TouchableOpacity>


                <TouchableOpacity style={{alignContent: 'center', alignItems: 'center', marginLeft: 20}} onPress={()=> setWa('busin')} style={{width: 50, height: 50, borderRadius: 50, backgroundColor: 'orange', marginTop: 40, marginLeft: 20}}>
                    <View style={{alignContent: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 11}}>
                        {/* <Text style={{color: 'white'}}>Next</Text>  */}
                        <Feather name="arrow-right" size={24} color="white" />      
                    </View>
              </TouchableOpacity>

             </View>


              


      </View>
    )

  }


  const myBusin = ()=>{

    return(
      <View style={styles.container}>
              <View style={{color:'black', paddingLeft: 10, marginTop: 20}}>
                <Text>Business Details ?</Text>  
              </View> 

              <View style={{backgroundColor:'#ebebeb', marginLeft: 5, height: 45, width: 300, borderRadius: 5, marginTop: 15}}>
                    <TextInput style={{color:'black', paddingLeft: 10, height: 40}}
                    placeholder="Your Business Name"
                    onChangeText={val => setBusName(val)}
                    // value={qty}
                    placeholderTextColor="#7d7d7d"
                    />
               
                </View>
               <View style={{backgroundColor:'#ebebeb', marginLeft: 5, height: 45, width: 300 , borderRadius: 5, marginTop: 15}}>
                    <TextInput style={{color:'black', paddingLeft: 10, height: 40}}
                    placeholder="What Industry Are You In?"
                    onChangeText={val => setIndustry(val)}
                    // value={qty}
                    placeholderTextColor="#7d7d7d"
                    />
               
                </View>

                  <View style={{flexDirection: 'row'}}>
                <TouchableOpacity style={{alignContent: 'center', alignItems: 'center'}} onPress={()=> setWa('services')} style={{width: 50, height: 50, borderRadius: 50, backgroundColor: 'orange', marginTop: 40, marginLeft: 20}}>
                    <View style={{alignContent: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 11}}>
                        {/* <Text style={{color: 'white'}}>Next</Text>  */}
                        <Feather name="arrow-left" size={24} color="white" />      
                    </View>
              </TouchableOpacity>


                <TouchableOpacity style={{alignContent: 'center', alignItems: 'center', marginLeft: 20}} onPress={()=> setWa('country')} style={{width: 50, height: 50, borderRadius: 50, backgroundColor: 'orange', marginTop: 40, marginLeft: 20}}>
                    <View style={{alignContent: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 11}}>
                        {/* <Text style={{color: 'white'}}>Next</Text>  */}
                        <Feather name="arrow-right" size={24} color="white" />      
                    </View>
              </TouchableOpacity>

             </View>

      </View>

                
    )
  }

  const myCountry = ()=>{

    return(
      <View style={styles.container}>
              <View style={{color:'black', paddingLeft: 10, marginTop: 20}}>
                <Text>Which Country Are You From ?</Text>  
              </View> 

              
               {country()}

                  <View style={{flexDirection: 'row'}}>
                <TouchableOpacity style={{alignContent: 'center', alignItems: 'center'}} onPress={()=> setWa('busin')} style={{width: 50, height: 50, borderRadius: 50, backgroundColor: 'orange', marginTop: 40, marginLeft: 20}}>
                    <View style={{alignContent: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 11}}>
                        {/* <Text style={{color: 'white'}}>Next</Text>  */}
                        <Feather name="arrow-left" size={24} color="white" />      
                    </View>
              </TouchableOpacity>


                <TouchableOpacity style={{alignContent: 'center', alignItems: 'center', marginLeft: 20}} onPress={()=> setWa('domain')} style={{width: 50, height: 50, borderRadius: 50, backgroundColor: 'orange', marginTop: 40, marginLeft: 20}}>
                    <View style={{alignContent: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 11}}>
                        {/* <Text style={{color: 'white'}}>Next</Text>  */}
                        <Feather name="arrow-right" size={24} color="white" />      
                    </View>
              </TouchableOpacity>

             </View>

      </View>

                
    )
  }

   const myDomain = ()=>{

    return(
      <View style={styles.container}>
              <View style={{color:'black', paddingLeft: 10, marginTop: 20}}>
                    <Text>Do you have a domain ?</Text>  
                  </View>

                  <View style={{width: '100%', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
                      {domaina()}

                  </View>

                  <View style={{flexDirection: 'row'}}>
                <TouchableOpacity style={{alignContent: 'center', alignItems: 'center'}} onPress={()=> setWa('country')} style={{width: 50, height: 50, borderRadius: 50, backgroundColor: 'orange', marginTop: 40, marginLeft: 20}}>
                    <View style={{alignContent: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 11}}>
                        {/* <Text style={{color: 'white'}}>Next</Text>  */}
                        <Feather name="arrow-left" size={24} color="white" />      
                    </View>
              </TouchableOpacity>


                <TouchableOpacity style={{alignContent: 'center', alignItems: 'center', marginLeft: 20}} onPress={()=> putUp()} style={{width: 50, height: 50, borderRadius: 50, backgroundColor: 'green', marginTop: 40, marginLeft: 20}}>
                    <View style={{alignContent: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 11}}>
                        {/* <Text style={{color: 'white'}}>Next</Text>  */}
                        <Feather name="check" size={24} color="white" />      
                    </View>
              </TouchableOpacity>

             </View>

      </View>

                
    )
  }

  const completed = () =>{

    return(
      <View>

        <Text>Your request has been submitted</Text>
      </View>

    )
  }






  const wholeApp =()=>{

    if(wa === 'details'){

        return(
        <View style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center'}}>
        {myDeta()}

        </View>
      )
    }

    if(wa === 'contact'){

        return(
        <View style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center'}}>
        {myContact()}

        </View>
      )
    }

    if(wa === 'services'){

        return(
        <View style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center'}}>
        {myServices()}

        </View>
      )
    }

    if(wa === 'busin'){

        return(
        <View style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center'}}>
        {myBusin()}

        </View>
      )
    }

    if(wa === 'country'){

        return(
        <View style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center'}}>
        {myCountry()}

        </View>
      )
    }

    if(wa === 'domain'){

        return(
        <View style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center'}}>
        {myDomain()}

        </View>
      )
    }

    if(wa === 'completed'){

        return(
        <View style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center'}}>
        {completed()}

        </View>
      )
    }


    
    

  }


  return (
    <View style={styles.container}>
      <View style={{marginBottom: 40}}>
           {wholeApp()}
        </View>
      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'space-evenly',
    
   
  },
  tinyLogo:{
    width: 500,
    height: 150,
  }
});
