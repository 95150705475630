/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNewA = /* GraphQL */ `
  mutation CreateNewA(
    $input: CreateNewAInput!
    $condition: ModelNewAConditionInput
  ) {
    createNewA(input: $input, condition: $condition) {
      id
      name
      surname
      idNumber
      email
      phone
      whatsapp
      country
      description
      busName
      package
      industry
      domain
      updatedAt
      createdAt
    }
  }
`;
export const updateNewA = /* GraphQL */ `
  mutation UpdateNewA(
    $input: UpdateNewAInput!
    $condition: ModelNewAConditionInput
  ) {
    updateNewA(input: $input, condition: $condition) {
      id
      name
      surname
      idNumber
      email
      phone
      whatsapp
      country
      description
      busName
      package
      industry
      domain
      updatedAt
      createdAt
    }
  }
`;
export const deleteNewA = /* GraphQL */ `
  mutation DeleteNewA(
    $input: DeleteNewAInput!
    $condition: ModelNewAConditionInput
  ) {
    deleteNewA(input: $input, condition: $condition) {
      id
      name
      surname
      idNumber
      email
      phone
      whatsapp
      country
      description
      busName
      package
      industry
      domain
      updatedAt
      createdAt
    }
  }
`;
export const createTodo = /* GraphQL */ `
  mutation CreateTodo(
    $input: CreateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    createTodo(input: $input, condition: $condition) {
      id
      name
      surname
      idNumber
      email
      phone
      whatsapp
      country
      description
      busName
      domaina
      domainb
      domainc
      createdAt
      updatedAt
    }
  }
`;
export const updateTodo = /* GraphQL */ `
  mutation UpdateTodo(
    $input: UpdateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    updateTodo(input: $input, condition: $condition) {
      id
      name
      surname
      idNumber
      email
      phone
      whatsapp
      country
      description
      busName
      domaina
      domainb
      domainc
      createdAt
      updatedAt
    }
  }
`;
export const deleteTodo = /* GraphQL */ `
  mutation DeleteTodo(
    $input: DeleteTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    deleteTodo(input: $input, condition: $condition) {
      id
      name
      surname
      idNumber
      email
      phone
      whatsapp
      country
      description
      busName
      domaina
      domainb
      domainc
      createdAt
      updatedAt
    }
  }
`;
